import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { CarrouselComponent } from '../shared/carrousel/carrousel.component';
import { ButtonComponent } from '../shared/button/button.component';
import { TranslateModule } from '@ngx-translate/core';
import { ProjectService } from '../../services/project.service';
import { Project } from '../../models/project.model';
import { Subscription } from 'rxjs';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-projects-row',
  standalone: true,
  imports: [CarrouselComponent, ButtonComponent, TranslateModule, RouterModule],
  templateUrl: './projects-row.component.html',
  styleUrl: './projects-row.component.scss'
})
export class ProjectsRowComponent implements OnDestroy, OnChanges {

  @Input() exclude?: string;

  private allProjects: Project[] = [];
  public projects: Project[] = [];
  private subscription = new Subscription();

  /**
   * Gets all projects and sorts them.
   * 
   * @param projectService Used to get the list of projects.
   */
  constructor(
    private projectService: ProjectService,
  ) {
    this.subscription.add(this.projectService.projects.subscribe((res) => {
      this.allProjects = res.sort((a, b) => b.order - a.order);
      this.projects = this.allProjects;
    }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    if (changes['exclude']) {
      this.projects = this.allProjects;
      this.projects.splice(this.allProjects.findIndex(project => project.id == changes['exclude'].currentValue), 1);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
