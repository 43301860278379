import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-partners',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './partners.component.html',
  styleUrl: './partners.component.scss'
})
export class PartnersComponent {
  public ngos = Array(28).fill("").map((val, i) => `/assets/images/logos/partners/NGOS/${i + 1}.png`)
  public unInternational = Array(9).fill("").map((val, i) => `/assets/images/logos/partners/UN-international/${i + 1}.png`)
  public redCross = Array(5).fill("").map((val, i) => `/assets/images/logos/partners/RED-CROSS/${i + 1}.png`)
  public others = Array(12).fill("").map((val, i) => `/assets/images/logos/partners/OTHERS/${i + 1}.png`)

  constructor() {
  }
}
