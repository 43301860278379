import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

const BOARD_MEMBERS = [{
  image: '/assets/images/pages/about/steve2.png',
  name: 'Steve Mcandrew',
  titles: 'pages.about.boardMembers.steve'
},{
  image: '/assets/images/pages/about/robert.png',
  name: 'Robert Patton',
  titles: 'pages.about.boardMembers.robert'
},{
  image: '/assets/images/pages/about/florent_del_pinto.png',
  name: 'Florent Del Pinto',
  titles: 'pages.about.boardMembers.florent'
},{
  image: '/assets/images/pages/about/sebastien.png',
  name: 'Sebastien Bonnefoy',
  titles: 'pages.about.boardMembers.sebastien'
}]

@Component({
  selector: 'app-board-members',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './board-members.component.html',
  styleUrl: './board-members.component.scss'
})
export class BoardMembersComponent {
  public boardMember = BOARD_MEMBERS;
}
