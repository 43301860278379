<div class="icon-list" [ngClass]="[variant]" *ngIf="content">
    <div *ngFor="let item of content; index as i">
        <div>
            <mat-icon *ngIf="item.icon" aria-hidden="true" [svgIcon]="item.icon"></mat-icon>
            <span *ngIf="!item.icon">{{("0" + (i + 1)).slice(-2)}}.</span>
        </div>
        <div>
            <h4>{{item.title | translate}}</h4>
            <p>{{item.content | translate}}</p>
        </div>
    </div>
</div>