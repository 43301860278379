import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../components/shared/button/button.component';
import { MatIconModule } from '@angular/material/icon';
import { HiringSectionComponent } from '../../components/hiring-section/hiring-section.component';
import { BoardMembersComponent } from './components/board-members/board-members.component';
import { TeamComponent } from './components/team/team.component';
import { ContactUsComponent } from '../../components/contact-us/contact-us.component';

/**
 * icon: svgIcon registered on app.component.ts
 * title: translation key for title
 * content: translation key for content
 */
const HIGHLIGHTS = [{
  icon: 'heart_circle',
  title: 'pages.about.highlights.item.0.title',
  content: 'pages.about.highlights.item.0.summary'
}, {
  icon: 'magic_star',
  title: 'pages.about.highlights.item.1.title',
  content: 'pages.about.highlights.item.1.summary'
},{
  icon: 'eye',
  title: 'pages.about.highlights.item.2.title',
  content: 'pages.about.highlights.item.2.summary'
}]

/**
 * title: translation key for title,
 * content: translation key for content
 */
const PRINCIPLES = [{
  title: 'pages.about.principles.item.0.title',
  content: 'pages.about.principles.item.0.summary'
},{
  title: 'pages.about.principles.item.1.title',
  content: 'pages.about.principles.item.1.summary'
},{
  title: 'pages.about.principles.item.2.title',
  content: 'pages.about.principles.item.2.summary'
},{
  title: 'pages.about.principles.item.3.title',
  content: 'pages.about.principles.item.3.summary'
}]

@Component({
  selector: 'app-about',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ButtonComponent,
    MatIconModule,
    HiringSectionComponent,
    BoardMembersComponent,
    TeamComponent,
    ContactUsComponent
  ],
  templateUrl: './about.component.html',
  styleUrl: './about.component.scss'
})
export class AboutComponent {
  public highlights = HIGHLIGHTS;
  public principles = PRINCIPLES;
}
