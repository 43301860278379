import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { Project, ProjectAdapter } from '../models/project.model';
import { environment } from '../../environments/environment';

/**
 *
 */
const PROJECTS_ROUTE = '/projects';

/**
 *
 */
@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  private $projects = new BehaviorSubject<Project[]>([]);

  /**
   * The constructor
   *
   * @param api the api service
   * @param projectAdapter the adapter project service
   */
  constructor(private api: ApiService, private projectAdapter: ProjectAdapter) {
    const url = environment.apiUrl + PROJECTS_ROUTE;
    this.api.get(url).subscribe((res) => {
      this.$projects.next(res.map((x: any) => this.projectAdapter.adapt(x)));
    });
  }

  /**
   * Get the observable of all project
   *
   * @returns Observable<Project>
   */
  get projects(): Observable<Project[]> {
    return this.$projects.asObservable();
  }

  /**
   * Get a project with it id
   *
   * @param id the id of the project
   * @returns BehaviorSubject<Project>
   */
  public getProject(id: string) {
    return this.$projects.getValue().find((x) => x.id.replace(/_/g,' ') === id.replace(/_/g,' '));
  }
}
