<div class="page-container">

    <ng-container *ngIf="project">
        <!-- Page header -->
        <div class="header">
            <h4>Entity</h4>
            <h2>{{project.name}}</h2>
        </div>
        <div class="summary">
            <div class="flex">
                <div
                    class="image"
                >
                    <img [src]="project.imgs.cardWeb">
                </div>
                <div>
                    <h3>About Project:</h3>
                    <div class="list">
                        <div class="title">
                            <div></div>
                            <h4>Services:</h4>
                        </div>
                        <div class="content flex">
                            <p class="chip" *ngFor="let service of services">
                                {{service}}
                            </p>
                        </div>
                        <div class="title">
                            <div></div>
                            <h4>Industry:</h4>
                        </div>
                        <div class="content">
                            {{project.details.industry}}
                        </div>
                        <div class="title">
                            <div></div>
                            <h4>Time-frame:</h4>
                        </div>
                        <div class="content">
                            {{project.details.timeFrame}}
                        </div>
                        <div class="title">
                            <div></div>
                            <h4>Team size:</h4>
                        </div>
                        <div class="content">
                            {{project.details.teamSize}} persons
                        </div>
                        <div class="title">
                            <div></div>
                            <h4>Client / Team:</h4>
                        </div>
                        <div class="content">
                            N/A
                        </div>
                        <div class="title">
                            <div></div>
                            <h4>Supported Sustainable Development Goals:</h4>
                        </div>
                        <div class="content flex">
                            <img *ngFor="let goal of project.details.goals" [src]="sdgs[goal]">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <!-- Page content -->
        <div class="sections-container">
            <div class="challenge">
                <div class="flex">
                    <div>
                        <h2>The challenge</h2>
                        <p>We dedicate our work to helping people in need by bringing tech tools to the international development and humanitarian sectors. This enables organizations to bridge the technological gap and enhance their impact.</p>
                    </div>
                    <div>
                        <app-custom-list [variant]="'simple-red'" [content]="challenges"></app-custom-list>
                    </div>
                </div>
                <div class="bg"></div>
            </div>
            <div class="flex">
                <div>
                    <h2>Our Solution</h2>
                    <p>We dedicate our work to helping people in need by bringing tech tools to the international development and humanitarian sectors. This enables organizations to bridge the technological gap and enhance their impact.</p>
                </div>
                <div>
                    <app-custom-list [variant]="'small-red'" [content]="solutions"></app-custom-list>
                </div>
            </div>
            <div *ngIf="images.length > 0">
                <h2>More Details</h2>
                <app-image-carousel [images]="images"></app-image-carousel>
            </div>
            <app-projects-row [exclude]="project.id"></app-projects-row>
            <app-contact-us></app-contact-us>
        </div>
    </ng-container>
</div>