import { Component, Input } from '@angular/core';
import { CarrouselComponent } from "../shared/carrousel/carrousel.component";
import { availableSuiteApps } from '../../constants/suite-apps';
import { ButtonComponent } from '../shared/button/button.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-suite-apps-row',
  standalone: true,
  imports: [CarrouselComponent, ButtonComponent, TranslateModule, CommonModule],
  templateUrl: './suite-apps-row.component.html',
  styleUrl: './suite-apps-row.component.scss'
})
export class SuiteAppsRowComponent {
  @Input() pricing?: boolean;

  public apps = availableSuiteApps;
}
