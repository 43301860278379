<div class="cards-container" *ngIf="currentImage">
    <div class="image-container">
        <div
            class="image"
            [style.background-image]="'url(' + currentImage + ')'"
            *ngFor="let currentImage of [ currentImage ]"
            [@fadeInOut]
        ></div>
    </div>
    <div class="button prev" *ngIf="currentPage > 0">
        <mat-icon aria-hidden="true" fontIcon="chevron_left" (click)="changePage(currentPage - 1)"></mat-icon>
    </div>
    <div class="button next" *ngIf="currentPage < availablePages - 1">
        <mat-icon aria-hidden="true" fontIcon="chevron_right" (click)="changePage(currentPage + 1)"></mat-icon>
    </div>
</div>
<div class="page-indicator" *ngIf="availablePages > 1">
    <div *ngFor="let item of [].constructor(availablePages); let i= index" [class.active]="currentPage == i"></div>
</div>