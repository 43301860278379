<div class="page-container">

    <!-- Page header -->
    <div class="header">
        <div class="flex">
            <div>
                <h4>{{'pages.projects.header.miniTitle' | translate}}</h4>
                <h1>{{'pages.projects.header.title' | translate}}</h1>
            </div>
            <div>
                <p>{{'pages.projects.header.summary1' | translate}}</p>
                <p>{{'pages.projects.header.summary2' | translate}}</p>
            </div>
        </div>
    </div>

    <!-- Page content -->
    <div class="sections-container">
        <div class="tags">
            <p class="tag" *ngFor="let tag of tags"
            [class.selected]="selectedTags.includes(tag)"
            (click)="updateTags(tag)"
        >
            {{tag | translate}}
        </p>
        </div>
        <div class="projects">
            <app-project-card *ngFor="let project of availableProjects" [content]="project"></app-project-card>
        </div>
    </div>
</div>