import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../../../components/shared/button/button.component';
import { MatIcon } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink, RouterModule } from '@angular/router';



@Component({
  selector: 'app-partners-banner',
  standalone: true,
  imports: [CommonModule, TranslateModule, ButtonComponent, MatIcon, MatTooltipModule, RouterModule],
  templateUrl: './partners-banner.component.html',
  styleUrl: './partners-banner.component.scss'
})
export class PartnersBannerComponent {
  public logos = [
    '/assets/images/logos/partners/OCHA.png',
    '/assets/images/logos/partners/ILO.png',
    '/assets/images/logos/partners/MDM.png',
    '/assets/images/logos/partners/UNICEF.png',
    '/assets/images/logos/partners/IFRC.png',
    '/assets/images/logos/partners/WHO.png',
    '/assets/images/logos/partners/TWB.png'
  ]
}
