import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ProjectService } from '../../services/project.service';
import { Subscription } from 'rxjs';
import { Project } from '../../models/project.model';
import { ProjectCardComponent } from '../../components/shared/project-card/project-card.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-projects',
  standalone: true,
  imports: [TranslateModule, ProjectCardComponent, CommonModule],
  templateUrl: './projects.component.html',
  styleUrl: './projects.component.scss'
})
export class ProjectsComponent {
  private subscription = new Subscription();
  private projects: Project[] = [];
  public availableProjects: Project[] = [];
  public tags: string[] = [];
  public selectedTags: string[] = [];

  /**
   * Gets all projects and sorts them.
   * 
   * @param memberService Used to get the first four members of RA.
   */
  constructor(
    private projectService: ProjectService,
  ) {
    this.subscription.add(this.projectService.projects.subscribe((res) => {
      const projects = res;
      this.projects = projects.sort((a, b) => b.order - a.order);
      // Get all the tags available for the projects
      this.projects.forEach(project => {
        project.details.newTags.forEach(tag => {this.tags.indexOf(tag) === -1 ? this.tags.push(tag) : null});
      })
      this.setAvailableProjects();
    }));
  }
  
  private setAvailableProjects() {
    this.availableProjects = (
      this.selectedTags.length > 0 ?
        this.projects.filter(project => this.selectedTags.every(tag => project.details.newTags.includes(tag))) :
        this.projects
      )
  }

  public updateTags(tag: string) {
    const tagIndex = this.selectedTags.indexOf(tag);
    if (tagIndex === -1) {
      this.selectedTags.push(tag);
    } else {
      this.selectedTags.splice(tagIndex, 1);
    }
    this.setAvailableProjects();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
