<div class="text">
    <p>{{'pages.home.partnersBanner.partners' | translate}}: </p>
    <div
        class="partners-link"
        [matTooltip]="'pages.home.partnersBanner.details' | translate"
        [routerLink]="'/partners'"
    >
        <p>{{'pages.home.partnersBanner.details' | translate}}</p>
        <mat-icon aria-hidden="true" svgIcon="minimal_arrow"></mat-icon>
    </div>
    <!-- <app-button [style]="'basic'" [color]="'accent'" [arrow]="true">
    </app-button> -->
</div>
<div class="banner">
    <div>
        <img *ngFor="let logo of logos" [src]="logo"/>
    </div>
    <!-- Duplicate the images for a seamless infinite scroll -->
    <div>
        <img *ngFor="let logo of logos" [src]="logo"/>
    </div>
    <div>
        <img *ngFor="let logo of logos" [src]="logo"/>
    </div>
</div>