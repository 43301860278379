export interface SuiteApp {
    code: string,
    icon: string,
    name: string,
    description: string,
    features: string[],
    state: 'comingSoon' | 'demoSoon' | 'available',
    prices: {
        name: string,
        description: string,
        yearlyCost: string, // Month cost if payed anually, in EUR
        monthlyCost: string, // Month cost if payed monthly, in EUR
        installation: string // Cost to install, in EUR
    }[]
}

export const availableSuiteApps: SuiteApp[] = [
    {
        code: 'PMS',
        icon: "/assets/images/apps/rms/icon.png",
        name: 'Purchasing & Procurement',
        description: 'PMS - Procurement & Purchasing Management System, streamlines sourcing & acquiring essential goods for humanitarian operations. Simplifying procurement tasks, boosting efficiency, & ensuring timely delivery of critical supplies.',
        features: [
            'Offline capability.',
            'Mobile & Tablet friendly.',
            'Advanced Kitting.',
            'Performance reporting & analysis.'
        ],
        state: 'comingSoon',
        prices: [
            {
                name: "Starter",
                description: "Basic Data Collection",
                yearlyCost: "386",
                monthlyCost: "386",
                installation: "1390"
            }
        ]
    },
    {
        code: 'SCMS',
        icon: "/assets/images/apps/cms/icon.png",
        name: 'Supply Chain',
        description: 'SCMS - Supply Chain Management System, optimizes inventory tracking, logistics, and resource distribution. It simplifies supply chain operations, ensuring timely delivery and efficient management of critical supplies on the ground.',
        features: [
            'Offline capability.',
            'Mobile & Tablet friendly.',
            'Advanced Kitting.',
            'Performance reporting & analysis.'
        ],
        state: 'demoSoon',
        prices: [
            {
                name: "Starter",
                description: "Basic Data Collection",
                yearlyCost: "386",
                monthlyCost: "386",
                installation: "1390"
            }
        ]
    },
    {
        code: 'WMS',
        icon: "/assets/images/apps/wms/icon.png",
        name: 'Warehouse & Stock',
        description: 'WMS - Warehouse Management System, designed for humanitarian field operations, streamlines inventory tracking, ensures efficient reporting, and simplifies collaboration, enabling teams to effectively manage, track, and optimize stock on the ground.',
        features: [
            'Offline capability.',
            'Mobile & Tablet friendly.',
            'Advanced Kitting.',
            'Performance reporting & analysis.'
        ],
        state: 'available',
        prices: [
            {
                name: "Starter",
                description: "Basic Data Collection",
                yearlyCost: "386",
                monthlyCost: "386",
                installation: "1390"
            }
        ]
    }
]