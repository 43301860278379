import { CommonModule } from '@angular/common';
import { Component, Input, SimpleChanges } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';

function FadeInOut(timingIn: number, timingOut: number): AnimationTriggerMetadata  {
  return trigger('fadeInOut', [
    transition(':enter', [
      style({display: 'none', opacity: 0}),
      animate(`${timingIn}ms ${timingOut}ms`, style({ opacity: 1, 'display': 'block' })),
    ]),
    transition(':leave', [
      style({}),
      animate(`${timingOut}ms`, style({ opacity: 0, 'display': 'none' })),
    ])
  ]);
}

@Component({
  selector: 'app-image-carousel',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  animations: [FadeInOut(300, 300)],
  templateUrl: './image-carousel.component.html',
  styleUrl: './image-carousel.component.scss'
})
export class ImageCarouselComponent {

  @Input() images: String[] = [];

  public availablePages = this.images.length;
  public currentPage = 0;

  public currentImage?: String;

  /**
   * 
   */
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.availablePages = this.images.length;
    this.setPageContent();
  }

  /**
   * Sets the renderedProjects to the correct ones based on the vurrent page and the projects per page
   */
  private setPageContent() {
    this.currentImage = this.images[this.currentPage];
  }

  /**
   * Changes the current page. First page is 0.
   * 
   * @param page Number indicating the page to change.
   */
  public changePage(page: number) {
    if (page >= this.availablePages || page < 0) {
      return;
    }
    this.currentPage = page;
    this.setPageContent();
  }
}
