<div class="items-container">
    <div class="logo" routerLink="/home"></div>
    <div class="nav-items">
        <ng-container *ngFor="let item of navItems">
            <div class="nav-item" [routerLink]="item.nav" [routerLinkActive]="'active'">
                <span>{{item.key | translate}}</span>
                <mat-icon aria-hidden="true" fontIcon="keyboard_arrow_down" [matMenuTriggerFor]="menu" *ngIf="item.children"></mat-icon>
                <mat-menu #menu="matMenu" class="custom-menu">
                    <div>
                        <div class="menu-item" *ngFor="let child of item.children" (click)="stopPropagation($event)" [routerLink]="[item.nav, child.nav]">
                            {{child.key | translate}}
                        </div>
                    </div>
                </mat-menu>
            </div>
        </ng-container>
    </div>
    <div class="actions">
        <div class="nav-item">
            EN
            <mat-icon aria-hidden="true" fontIcon="keyboard_arrow_down"></mat-icon>
        </div>
        <app-button [style]="'outline'" [color]="'black'">{{'component.navigation.trial' | translate}}</app-button>
        <app-button [style]="'flat'" [color]="'accent'" [arrow]="true" (click)="goToContactForm()">
            {{"shared.talk" | translate}}
        </app-button>
    </div>
</div>