<div class="page-container">

    <!-- Page header -->
    <div class="header">
        <h1>{{'pages.partners.header.title' | translate}}</h1>
    </div>

    <!-- Page content -->
    <div class="sections-container">
        <div>
            <h4>{{'pages.partners.ngos' | translate}}</h4>
            <div class="logos-container">
                <div *ngFor="let i of ngos" [style.background-image]="'url(' + i + ')'"></div>
            </div>
        </div>
        <div>
            <h4>{{'pages.partners.unInternational' | translate}}</h4>
            <div class="logos-container">
                <div *ngFor="let i of unInternational" [style.background-image]="'url(' + i + ')'"></div>
            </div>
        </div>
        <div>
            <h4>{{'pages.partners.redCross' | translate}}</h4>
            <div class="logos-container">
                <div *ngFor="let i of redCross" [style.background-image]="'url(' + i + ')'"></div>
            </div>
        </div>
        <div>
            <h4>{{'pages.partners.others' | translate}}</h4>
            <div class="logos-container">
                <div *ngFor="let i of others" [style.background-image]="'url(' + i + ')'"></div>
            </div>
        </div>
    </div>
</div>