<ng-container *ngIf="content">
    <div class="chip">
        <div class="icon" [style.background-image]="'url(' + content.icon + ')'"></div>
        <span>{{content.code.slice(0, 1)}}<span>{{content.code.slice(1)}}</span></span>
    </div>
    
    <div class="content">
        <h3>{{content.name}}</h3>
        <p>{{content.description}}</p>
        <div class="list">
            <div *ngFor="let feature of content.features">
                <mat-icon aria-hidden="true" fontIcon="check"></mat-icon>
                <span>{{feature}}</span>
            </div>
        </div>
    </div>
    
    <div class="buttons" [class.pricing]="pricing">
        <ng-container *ngIf="!pricing; else pricing">
            <app-button [style]="'basic'" [color]="'accent'" *ngIf="content.state == 'available'; else nonClickable">
                {{'component.suiteAppCard.bookDemo' | translate}}
            </app-button>
            <ng-template #nonClickable>
                <span>{{'component.suiteAppCard.' + content.state | translate}}</span>
            </ng-template>
        </ng-container>
        <ng-template #pricing>
            <div>
                <p>Starting from</p>
                <h3>{{content.prices[0].yearlyCost}} /month</h3>
            </div>
        </ng-template>
        <app-button [style]="'flat'" [color]="'accent'" [arrow]="true">
            {{'shared.learn' | translate}}
        </app-button>
    </div>
</ng-container>